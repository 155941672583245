import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FiArrowRight, FiFile, FiBox, FiShoppingCart, FiActivity, FiEye} from "react-icons/fi";
import { FaGithub } from "react-icons/fa";
import { RiCheckboxCircleLine } from "react-icons/ri";
import styled from 'styled-components'
import { Icon, Project } from "./helpers.js"

var slugify = require('slugify')

const Flex = styled.div`
    display:flex;
    justify-content: space-between;
    &:not(:last-of-type) 
    {
        margin-bottom:-0.5em;
    }
    svg 
    {
        margin-right:0.3em;
    }

    
    p:last-of-type
    {
        text-align:right;
    }

    @media screen and (max-width: 1330px) and (min-width: 1025px) {

        &.sidebar {
            flex-wrap:wrap;

            p:last-of-type {
                width:100%;
                text-align:left;
                margin-left:1.7em;
                margin-top:-1.8em;
                margin-bottom:1.3em;
                font-size:0.8em;
            }
        }        
    }

    @media screen and (max-width: 420px) {
        
        flex-wrap:wrap;

        p:last-of-type {
            width:100%;
            text-align:left;
            margin-left:1.7em;
            margin-top:-1.8em;
            margin-bottom:1.3em;
            font-size:0.8em;
        }
            
    }

`;

const Git = styled.a`
    background-color:#222;
    color:#fff !important;
    font-family: 'Arial', sans-serif;
    font-size:0.9em;
    margin-bottom:1em;
    margin-top:0.5em;
    border-radius:3px;
    display:block;
    text-align:center;
    padding:0.5em;
    text-decoration:none;
    :hover {
        color:#fff;
        background-color:#333;
    }
`;

const TitleImg = styled.div`
    display:flex;
    align-items:center;

    & > div.imgDiv {
        max-width:100px;
        width:50%;
        height: 4em;
        margin-right:0.8em;

        & > * {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            border-radius:4px;
        }
        
    }

    @media screen and (max-width: 450px) {
        
        flex-wrap:wrap;

        & > div:last-of-type {
            width:100%;
        }

    }
`;

export default ({ className, data, active }) => {

    var items;
    const logs = data.logs;
    for (var i = logs.length-1; i >=0; i--) {

        var date = new Date(logs[i].date).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });

        if (logs[i].title === active)
        {
            items = <>{items}<Flex className={className}><p><Icon><FiArrowRight /></Icon>{logs[i].title}</p><p>{date}</p></Flex></>
        } else {
            items = <>{items}<Flex className={className}><p><Icon><FiFile /></Icon>
                        <Link to={"/" + slugify(data.name, {lower: true}) + '/logs/'  + slugify(logs[i].title, {lower: true}) + '/'}>
                            {logs[i].title}
                        </Link></p><p>{date}</p></Flex>
                    </>
        }
    }

    var color;
    if (data.status==='Finished')
        color = 'finished'
    else if (data.status === 'In research')
        color = 'research'
    else if (data.status === 'For sale')
        color = 'sale'
    else 
        color = 'ongoing'

    var gitlink;
    if (className === "sidebar" && typeof data.git !== "undefined" ) {
        gitlink = <Git href={data.git} target="_blank" rel="noopener noreferrer"><Icon><FaGithub /></Icon> View on GitHub</Git>;
    }

    var img;
    if (className !== "sidebar")
    {
        img = <div className='imgDiv'><Img fadeIn={false} fluid={{ ...data.imgData, aspectRatio: 1.6 }} style={{ marginBottom: '2em' }} alt="Project image" /></div>
    }

    //<tr><th>Project logs</th><th></th></tr>
    //<Img fadeIn={false} fluid={data.imgData} alt={data.name} />
    return (<Project>        
        
        <TitleImg>
        {img}    
        <div>
        <h3>{data.name}</h3>        
        <h4><Icon><FiBox /></Icon> <span className="projectID"><Link to={"/" + slugify(data.name, {lower: true}) + '/'}>Project {data.id}</Link></span> <span className={"projectTag " + color}>
                    {data.status === 'For sale' ? <Link to={data.productUrl}><Icon><FiShoppingCart /></Icon> Buy this</Link> : ''}
                    {data.status === 'Finished' ? <><Icon><RiCheckboxCircleLine /></Icon> Finished</> : ''}
                    {data.status === 'Ongoing' ? <><Icon><FiActivity /></Icon> Ongoing</> : ''}
                    {data.status === 'In research' ? <><Icon><FiEye /></Icon> In research</> : ''}</span></h4>
        </div>        
        </TitleImg>

        <p>{data.description}</p>    

        {items}    

        {gitlink}
    </Project>);

}