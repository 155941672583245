import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, Warning, FirstP, Extern, Pre, Javascript, Arduino } from "./../../../components/helpers.js"
import { SPIFFS, PROGMEM } from "./../../../components/definitions.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <Warning>This post explains the reasoning and philosophy behind the ESP8266 IoT Framework.
        Since the framework is evolving over time, some of this post might be outdated. Find the latest
            on <Extern href="https://github.com/maakbaas/esp8266-iot-framework">GitHub</Extern>.</Warning>

        <FirstP>The first component I will discuss is the web server which presents the web interface to configure WiFi and other settings from the browser. 
            This web interface is developed in React, and communicates with the ESP8266 through an API. Webpack is used to merge the GUI content into a single gzipped file, 
            which is automatically converted into a byte array and stored in the ESP8266 <PROGMEM/>, avoiding the need for <SPIFFS/>.
        </FirstP>

        <p>In the schematic below, you will find a high level overview of what the web server component in the framework will be doing. It will expose two things to the outside world.
        First of all, a web interface that the user can interact with, and secondly an API that this web interface can interact with. </p>

        <p>The reason for this architecture is to allow a
        clear split between the web interface and the ESP8266 application, making customization of the GUI easier, and allowing the web interface to be developed with 
        a normal front-end toolchain. The general approach taken in this framework is comparable with a <Extern href="">JAMstack</Extern> website.
        </p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Architecture" />
        <Caption>The architecture of the web server class is shown in blue</Caption>

        <p>First I will show how the automatic integration of the web interface in the application is handled. Next I will show an example of the API and web interface 
            implementation using the <SPIFFS /> file manager as an example.
            The full code for the web server class can be found on <Extern href="https://github.com/maakbaas/esp8266-iot-framework/blob/master/src/webServer.cpp">GitHub</Extern>.
        </p>

        <h3>Generating html.h using webpack</h3>

        <p>
            For this framework I wanted the web interface to be embedded into the application, so that it will be update automatically with each build. I think this is more
            elegant than the more common solution of storing the HTML files in the <SPIFFS /> file memory. The best way of doing this is by storing the binary representation of 
            the files in a byte array in <PROGMEM /> memory.</p>
        <p>
            As with many ideas, I am not the first one to do this, and I found a source of inspiration 
            at <Extern href="https://tinkerman.cat/post/embed-your-website-in-your-esp8266-firmware-image/">Tinkerman</Extern>. But instead of Gulp I wanted to use webpack,
            since I already used that for development anyway. For those of you with an electronics background, this might be unknonw territory. 
            Basically, <Extern href="https://levelup.gitconnected.com/what-is-webpack-4fdb624597ae">webpack</Extern> is a method to translate development 
            Javascript code into the final code that is served by a browser. This allows for much more flexible development
            methods. For our purposes here, I first need a few webpack plugins to <Pre>webpack.config.js</Pre>.
        </p>

        <Javascript>{
                `const { CleanWebpack } = require('clean-webpack-plugin');
const InlineSource = require('html-webpack-inline-source-plugin');
const Compression = require('compression-webpack-plugin')
const EventHooks = require('event-hooks-webpack-plugin');
`}</Javascript>

        <p>The first three take care of preparing the bundle from the React web app.
        With these, all source Javascript and CSS will be deleted and instead inlined into the <Pre>index.html</Pre> file.
        Finally, the resulting bundle will be gzipped to significantly reduce its size. The last plugin allows to create a node.js callback function
        that will be executed when webpack is finished. In this callback the bundle is automatically generated into a byte array:</p>

        <Javascript>
            {
                `new EventHooksPlugin({
    done: () => {
        if (argv.mode === 'production')
        {
            var source = './dist/index.html.gz';
            var destination = './src/generated/html.h';

            var wstream = fs.createWriteStream(destination);
            wstream.on('error', function (err) {
                console.log(err);
            });

            var data = fs.readFileSync(source);
            
            wstream.write('#ifndef HTML_H\\n');
            wstream.write('#define HTML_H\\n\\n');
            wstream.write('#include <Arduino.h>\\n\\n');                

            wstream.write('#define html_len ' + data.length + '\\n\\n');

            wstream.write('const uint8_t html[] PROGMEM = {')

            for (i = 0; i < data.length; i++) {
                if (i % 1000 == 0) wstream.write("\\n");
                wstream.write('0x' + ('00' + data[i].toString(16)).slice(-2));
                if (i < data.length - 1) wstream.write(',');
            }

            wstream.write('\\n};')

            wstream.write('\\n\\n#endif\\n');

            wstream.end();

            del([source]);
            del('./dist/');
        }
    }
})
`};</Javascript>

        <p>The generated bundle is saved as <Pre>html.h</Pre> and included in the web server class to serve it to clients. The webpack script 
        is <Extern href="https://docs.platformio.org/en/latest/projectconf/advanced_scripting.html#before-pre-and-after-post-actions">
            hooked</Extern> into PlatformIO to
        automatically build and include the latest version of the interface into the ROM. Next, let's look at the API and the web interface itself.</p>

        <h3>The API</h3>

        <p>In order to have the API embedded in the web server class, it has to communicate with all the other parts of the application, which means these have to be included
            in the web server class. In the specific example of the file manager, this means that we have to include the <Pre>File</Pre> class.
        </p>

        <Arduino>
        {
            `#include <FS.h>`
        }
        </Arduino>
        
        <p>The two main API methods for the file manager are to list file information and to delete a file. These two web server callbacks will be shown below, starting with
            the function to create a file listing.
        </p>
        
        <h4>File listing</h4>

        <Arduino>
            {
                `server.on(PSTR("/api/files/get"), HTTP_GET, [](AsyncWebServerRequest *request) 
{
    String JSON;
    StaticJsonDocument<1000> jsonBuffer;
    JsonArray files = jsonBuffer.createNestedArray("files");
`
            }
        </Arduino>

        <p>The <Pre>server.on</Pre> callback is part of ESPAsyncWebServer, which is handles the low level web server implementation.
        This means that when the URL <Pre>/api/files/get</Pre> is requested, the server will call this function and start creating a response by
        initializing a <Pre>StaticJsonDocument</Pre> using the ArduinoJSON class. Next, the JSON object will be filled with the right information:</p>

        <Arduino>
            {
                `    //get file listing
    Dir dir = SPIFFS.openDir("");
    while (dir.next())
        files.add(dir.fileName().substring(1));

    //get used and total data
    FSInfo fs_info;
    SPIFFS.info(fs_info);
    jsonBuffer["used"] = String(fs_info.usedBytes);
    jsonBuffer["max"] = String(fs_info.totalBytes);`
            }
        </Arduino>   

<p>First the filenames are added to an array, and next two individual values are added to the JSON object to indicate the used bytes, and total available bytes of the storage. 
    Finally the JSON is serialized and sent out as a response to the API request:</p>

        <Arduino>
            {
                `    serializeJson(jsonBuffer, JSON);
    request->send(200, PSTR("text/html"), JSON);
});
`
            }
        </Arduino>   

        <h4>Removing a file</h4>

        <p>The API call for removing a file is shown below. As you can see it follows the same format, but in this case only an empty <Pre>200</Pre> request is sent 
        to acknowledge that the request was received.</p>

        <Arduino>
            {
                `server.on(PSTR("/api/files/remove"), HTTP_GET, [](AsyncWebServerRequest *request) 
{
    SPIFFS.remove("/" + request->arg("filename"));
    request->send(200, PSTR("text/html"), "");
});`
            }
        </Arduino>

        <h3>The Web Interface</h3>

        <p>Finally, this API will be used by the web interface to implement the required functionality. The page for the file manager looks as follows:</p>
        
        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Web interface" />
        <Caption>The web interface for the file manager</Caption>

        <p>When the page is loaded the function <Pre>fetchData</Pre> is called:</p>

        <Javascript>{`function fetchData() {
    fetch('/api/files/get')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setState(data);
        });
}`}</Javascript>

    <p>This function requests the file info object from the API and sets it as the state of the parent React component to show the file listing.
        As you can see there is also a delete button for each file, which links to the second API function:
    </p>

        <Javascript>{`<Fetch href={'/api/files/remove?filename=' + state.files[i]} onFinished={fetchData}>
    <RedButton title="Remove file"><Trash2 /></RedButton>
</Fetch>`}</Javascript>

    <p>When a response is received from the server that the file has been deleted, <Pre>fetchData</Pre> is called again to refresh the file listing.
    </p>

    <h3>Full Source Code</h3>

    <p>This post only contained some snippets of the code to explain the high level approach that was taken. The full implementation for the ESP8266 IoT 
        framework is found on <Extern href="https://github.com/maakbaas/esp8266-iot-framework">GitHub</Extern>. In that repository, 
        the documentation for the web server and API functions
        can be found <Extern href="https://github.com/maakbaas/esp8266-iot-framework/blob/master/docs/web-server.md">here</Extern>.
    </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "framework_web.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "filemanager.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
