import styled from 'styled-components'
import React from "react"

const Abbr = styled.abbr`

    text-decoration:none !important;
    border-bottom:1px dotted !important;
    cursor:help;

`;

export const SPIFFS = () => (<Abbr title="SPIFFS is an abbreviation for 'Serial Peripheral Interface Flash File System', which is a way to store files on embedded systems.">SPIFFS</Abbr>)

export const PROGMEM = () => (<Abbr title="PROGMEM is part of the flash memory where read-only variables can be stored to reduce RAM usage.">PROGMEM</Abbr>)

export const EEPROM = () => (<Abbr title="EEPROM is specific memory, or a dedicated portion of the flash to store variables that persist when the device is turned off.">EEPROM</Abbr>)

export const RTC = () => (<Abbr title="Real time clock">RTC</Abbr>)

export const NVM = () => (<Abbr title="Non volatile memory">NVM</Abbr>)

export const PWM = () => (<Abbr title="Pulse width modulation">PWM</Abbr>)

export const BLDC = () => (<Abbr title="Brushles DC motor">BLDC</Abbr>)
