import React, { useState } from 'react';
import Page from "./page.js"
import { Link } from "gatsby"
import ProjectList from "../components/project-list.js"
import { Icon } from "./../components/helpers.js"
import { TiHome } from "react-icons/ti";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Helmet } from 'react-helmet'
import ContactForm from "./../components/form.js"
import Share from "./../components/share.js"
import styled from 'styled-components'

var slugify = require('slugify')

const Footer = styled.div`
        margin-top:4px;
        padding-top:10px;
        border-top: 1px solid #ddd;
    }`

const Anchor = styled.a`
        position:relative;
        top:-100px;
    }`

export default (props) => {
        const [form, showForm] = useState(0);
        var footer;
        if (form===1)
            footer = <Footer>                
                <p><Anchor id="contact"></Anchor>Questions, comments or ideas? Please let me know.</p><ContactForm /></Footer>
        else
            footer = <Footer>                
                <p><Anchor id="contact"></Anchor>Questions, comments or ideas? Please <a href="#contact" onClick={() => showForm(1)}>let me know <Icon><MdKeyboardArrowDown /></Icon></a></p></Footer>
            
        return (
        <><Helmet>
                <title>{props.pageContext.log.h1} - Maakbaas</title>
                <meta name="description" content={props.pageContext.log.description} />
        </Helmet>
        <Page 
                    sidebar={<><ProjectList className="sidebar" active={props.pageContext.log.title} data={props.pageContext.project} /></>}
                    sidebar2={<p><Icon><TiHome /></Icon> <Link to="/">Back to home</Link></p>}
                    content={<>{props.children}    
                    <Share url={"https://maakbaas.com/" + slugify(props.pageContext.project.name, { lower: true }) + '/logs/' + slugify(props.pageContext.log.title, { lower: true }) + '/'} />                  
                    {footer}</>}
                    title={props.pageContext.log.h1}
                    color1={props.pageContext.project.color1}
                    color2={props.pageContext.project.color2}
        /></>);    
}