import React from "react"
import styled from 'styled-components'

const Form = styled.form`
    input, textarea {
        padding:0.5em;
        width:calc(100% - 1em);        
    }

    textarea {
        height:8em;
    }
`;

const Message = styled.p`
    background-color:#f7fff0;
    border:1px solid #70b830;
    color:#70b830;
    padding:0.5em;
    width:calc(100% - 1em);  
    border-radius:3px;
`;

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: "", email: "", message: "", fb: "" };
    }

    /* Here’s the juicy bit for posting the form submission */
    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...this.state })
        })
            .then(() => {
                this.setState({ name: "", email: "", message: "", fb: "Your message was received successfully!" })              
            })
            .catch(error => this.setState({ fb: error }));

        e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { name, email, message } = this.state;
        var msg;
        if (this.state.fb.length>0)
            msg = <Message>{this.state.fb}</Message>;

        return (<>{msg}
            <Form onSubmit={this.handleSubmit}>
                <p>
                    <label htmlFor="name">
                        Name: <input aria-label="name" type="text" id="name" name="name" value={name} onChange={this.handleChange} required='required' />
                    </label>
                </p>
                <p>
                    <label htmlFor="email">
                        Email: <input aria-label="email" type="email" id="email" name="email" value={email} onChange={this.handleChange} required='required' />
                    </label>
                </p>
                <p>
                    <label htmlFor="message">
                        Message: <textarea aria-label="message" id="message" name="message" value={message} onChange={this.handleChange} required='required' />
                    </label>
                </p>
                <p>
                    <button type="submit">Send</button>
                </p>
            </Form>
            </>
        );
    }
}

export default ContactForm;